<template>
    <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        id='custom-class'
    >
        <a-form-item ref="old_password" label="旧密码" name="old_password">
            <a-input
                v-model:value="formState.old_password"
                type='password'
                size='large'
            />
        </a-form-item>
        <a-form-item ref="new_password" label="新密码" name="new_password">
            <a-input
                v-model:value="formState.new_password"
                type='password'
                size='large'
            />
        </a-form-item>
        <a-form-item ref="confirm_new_password" label="确认密码" name="confirm_new_password">
            <a-input
                v-model:value="formState.confirm_new_password"
                @change="changeName"
                type='password'
                size='large'
            />
        </a-form-item>

    </a-form>
    <section class="operation-btn">
        <a-button style="margin-right: 10px" @click="resetForm">取消</a-button>
        <a-button type="primary" @click="onSubmit" :loading="loading"
            >确定</a-button
        >
    </section>
</template>
<script lang="ts" setup>
import { reactive, ref, toRaw, nextTick, computed } from "vue";
import type { UnwrapRef } from "vue";
import type { Rule } from "ant-design-vue/es/form";
import { editMyPassword } from "@/apis/userManager";
import { message } from "ant-design-vue";
import router from "@/router";
import Cookies from "js-cookie";

interface FormState {
    old_password: string;
    new_password: string;
    confirm_new_password: string;
}
const formRef = ref();
const labelCol = { span: 5 };
const wrapperCol = { span: 13 };
const formState: UnwrapRef<FormState> = reactive({
    old_password: "",
    new_password: '',
    confirm_new_password: ''
});


const rules = computed(() => {
    return {
        name: [
            {
                required: true,
                message: "请输入用户名",
                trigger: "change",
            },
        ],
        new_password: [
            {
                required: true,
                message: "请输入密码",
                trigger: "change",
            },
            {
                min: 8,
                message: "密码长度不能少于8位",
                trigger: "change",
            },
            {
                pattern: /^(?=(.*[A-Z]){0,})(?=(.*[a-z]){0,})(?=(.*\d){0,})(?=(.*[!@#$%^&*(),.?:|<>]){0,})(?!.*\s).{8,}$/,
                message: "密码必须包含大写字母、小写字母、数字和特殊字符中的至少三类",
                trigger: "change",
            },
        ],
        confirm_new_password: [
            {
                required: true,
                message: "请确认密码",
                trigger: "change",
            },
            {
                validator: (rule, value, callback) => {
                    if (value !== formState.new_password) {
                        return Promise.reject("两次输入的密码不一致");
                    } else {
                        return Promise.resolve();
                    }
                },
                trigger: "change",
            },
        ],
    };
});

const onSubmit = () => {
    formRef.value
        .validate()
        .then(async () => {
            await handleFinish();
            loading.value = false;
        })
        .catch((error) => {
            console.log("error", error);
            loading.value = false;
        });
};
const resetForm = () => {
    formRef.value.resetFields();
};

const changeName = () => {
    nextTick(() => {
        formRef.value.validateFields("password");
        formRef.value.validateFields("confirm_password");
    });
};
const loading = ref(false);
const handleFinish = async () => {
    loading.value = true;
    const data = toRaw(formState);
    const { old_password, confirm_new_password, new_password } = data;

    const result = await editMyPassword({
        old_password,
        new_password,
        confirm_new_password
    });
    loading.value = false;
    if (result.code === 0) {
        message.success("修改成功");
        router.push({
            path: '/login'
        })
    }
};
</script>


<style lang="scss" scoped>
.operation-btn {
    display: flex;
    justify-content: right;
}
.ant-input-affix-wrapper {
    border-radius: 4px;
}
.ant-form-item-label,
.ant-form-item-label > label {
    font-size: 16px;
    color: #141414;
    font-weight: 500;
}
.ant-input-affix-wrapper,
.ant-input {
    background-color: rgba(247, 248, 249, 1);
    &::placeholder {
        color: rgba(191, 191, 191, 1);
        font-size: 15px;
    }
    border-radius: 4px;
    width: 360px;
}
.ant-select {
    width: 360px;
}
#custom-class :deep(.ant-select-selector) {
    background-color: rgba(247, 248, 249, 1);
    border-radius: 4px;
    width: 360px;
}
#custom-class {
    .ant-select-selection-placeholder {
        color: rgba(191, 191, 191, 1);
        font-size: 15px;
    }
}

#form_item_owner::placeholder {
    font-size: 14px;
}
</style>
