<template>
    <div class='page-login'>
        <div class='box-card'>
            <img :src="logo" alt="mucron" >
        <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        id='custom-user'
    >

    <a-form-item ref="username" label="邮箱" name="username">
            <a-input
                v-model:value="formState.username"
                class='custom-input'
            />
        </a-form-item>

    <a-form-item ref="password" label="密码" name="password">
            <a-input
                v-model:value="formState.password"
                class='custom-input'
                type="password"
            />
        </a-form-item>
</a-form>
    <div class='btn'>
      <a-button type="primary" @click="onSubmit" :loading="loading"
            >登录</a-button
        >
      </div>
      </div>
    </div>
</template>

<script lang="ts" setup>
import { reactive, ref, toRaw, nextTick, computed, onBeforeMount } from "vue";
import type { UnwrapRef } from "vue";
import type { Rule } from "ant-design-vue/es/form";
import { Loginin, getUserById } from "@/apis/user";
import { message } from "ant-design-vue";
import router from "@/router";
import logo from '@/assets/images/logo.svg'
import Cookies from 'js-cookie';
import { defineUserStore } from "@/stores/user";

const userStore = defineUserStore();

interface FormState {

    password: string;
    username: string;
}
const formRef = ref();
const formState: UnwrapRef<FormState> = reactive({
    password: "",
    username: ''
});
const rules = computed(() => {
    return {
        username: [
            {
                required: true,
                message: "请输入用户名",
                trigger: "change",
            },
        ],
        password: [
            {
                required: true,
                message: "请输入密码",
                trigger: "change",
            },

        ],

    };
});
const onSubmit = () => {
    formRef.value
        .validate()
        .then(async () => {
            await handleFinish();
            loading.value = false;
        })
        .catch((error) => {
            console.log("error", error);
            loading.value = false;
        });
};

const loading = ref(false);
const handleFinish = async () => {
    loading.value = true;
    const data = toRaw(formState);
    const {  password, username } = data;

    const result = await Loginin({
        password,
        username
    });
    loading.value = false;
    if (result.code === 0) {
        message.success("登录成功");
        const {is_admin,token ,uid,username} = result.data;
        const base = {
            base_info: {
                is_admin,
                token,
                uid,
                username,
            },
            role_info: {
                role_id: is_admin || 0,
            },
        }
        userStore.setUser(base);
        Cookies.set("is_admin", is_admin)
        Cookies.set("token", token)
        Cookies.set("goUId", uid)
        Cookies.set("username", username);
        await getUserByIds(uid)
        router.push({
            path: '/'
        })
    }
};

const getUserByIds = async (uId) => {
    const data = await getUserById(uId);
    if (data.code === 0) {
        const res = data.data
            ? {
                  base_info: data.data,
                  role_info: {
                      role_id: data.data?.is_admin || 0,
                  },
              }
            : {};

        userStore.setUser(res);
        const roleId = res?.role_info?.role_id || "";
        roleId && userStore.setRoutes([roleId]);

    }
};
</script>
<style lang='scss' scoped>
.page-login{
    background-image: url(../../assets/images/login.png);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-box-shadow: -.026667rem .08rem .4rem 0 rgba(0, 0, 0, .4);
    box-shadow: -.026667rem .08rem .4rem 0 rgba(0, 0, 0, .4);
    font-weight: 700;
    height: 100vh;
    position: relative;
    text-align: left;
    color: #fff;
}

.page-login .box-card {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border: none !important;
    border-radius: 10px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 300px;
    left: 50%;
    padding: 0 .533333rem;
    position: absolute;
    top: 40%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50% );
    width: 300px;
    text-align: center;
    img{
        margin-bottom: 30px;
    }
    .btn {
        margin-top: 60px;
    }
}
.custom-input {
    width: 200px;
}
.ant-form-item {
    display: flex;
    justify-content: flex-start;
}
</style>
