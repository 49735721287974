<template>
    <div class="avbox" @click="preventClose">
        <div class="av-out-wrap">
            <div v-if="name" class="avatar-box">
                {{ text }}
            </div>
            <div v-if="!drawerVisible" class="real-name">{{ name }}</div>
            <LeftOutlined class="icon-arrow" v-if="drawerVisible" />
            <RightOutlined class="icon-arrow" v-if="!drawerVisible" />
        </div>
        <div
            v-if="showLoginOut"
            class="av-login-wrap"
            :class='{"av-login-pwd-wrap": mode === "gcs"}'
            >
            <a-button v-if='mode === "gcs"' class="av-login-btn" type="text" :icon="h(UnlockOutlined)" @click="updateUser"
                >修改密码</a-button
            >
            <a-button
                class="av-login-btn"
                type="text"
                :icon="h(LogoutOutlined)"
                @click="loginout"
                >退出登录</a-button
            >
        </div>
    </div>
</template>
<script lang="ts" setup>
import { computed, ref, h } from "vue";
import {
    RightOutlined,
    LeftOutlined,
    UnlockOutlined,
    LogoutOutlined,
} from "@ant-design/icons-vue";
import { defineUserStore } from "@/stores/user";
import localStorage from "@/utils/localStorage";
import { useRouter } from "vue-router";

import { Logout } from "@/apis/user";
import Cookies from "js-cookie";

const emits = defineEmits(["update:modelValue"]);
const props = defineProps({
    modelValue: {
        type: Boolean,
        default: () => {
            return true;
        },
    },
    name: {
        type: String,
        required: true,
        default: "",
    },
});
const text = computed(() => {
    return props.name.replace("v_", "").trim().substring(0, 1).toUpperCase();
});
const mode = computed(()=>{
    return import.meta.env.VITE_APP_MODE
})
const drawerVisible = computed({
    get() {
        return props.modelValue;
    },
    set(val) {
        emits("update:modelValue", val);
    },
});

const showLoginOut = ref<Boolean>(false);

//退出登录
const userStore = defineUserStore();

const result = ref<any>();
const loginout = async () => {
    const mode = import.meta.env.VITE_APP_MODE;
    if (mode === "gcs") {
        Cookies.remove('token');
        Cookies.remove('uid');
        Cookies.remove('username');
        Cookies.remove('is_admin');
        localStorage.remove("user");
        userStore.removeUser();
        console.log('loginout');
        router.push({
            path: "/login",
        });
        return;
    }
    result.value = await Logout();
    if (result.value.code === 0) {
        localStorage.remove("user");
        userStore.removeUser();
        window.location.href = result.value.data;
    }
};
const router = useRouter();

const updateUser = () => {
    router.push({
        name: "UpdateUser",
    });
};
const preventClose = (event) => {
      event.stopPropagation();
      if(showLoginOut.value){
        window.removeEventListener('click', handleClick);
      }else {
        window.addEventListener('click', handleClick);
      }
      showLoginOut.value = !showLoginOut.value;
    };
     // 监听点击事件
    const handleClick = (event) => {
      // 检查点击的目标是否在区域内
      const avbox:any = document.querySelector('.avbox');
      if (avbox && !avbox.contains(event.target)) {
        // 如果点击的是区域外的其他地方，则关闭区域
        showLoginOut.value = false;
        window.removeEventListener('click', handleClick);
      }
    };
</script>
<style scoped lang="scss">
.avatar-box {
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    border-radius: 50%;
    box-shadow: 1px 2px 6px 0px rgba(0, 102, 255, 0.25);
    background: linear-gradient(275deg, #0075ff 31.6%, #00a3ff 96.64%);
}
.small-size {
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 4px;

    color: #fff;
    text-align: center;
    font-family: PingFang SC;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
}
.av-name {
    display: flex;
    justify-content: left;
    margin-left: 28px;
    margin-bottom: 32px;
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
}
.real-name {
    margin-left: 8px;
    line-height: 28px;
    height: 28px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
}
.icon-arrow {
    margin-left: 5px;
}
.avbox {
    position: relative;
    height: 96px;
    .av-out-wrap {
        display: flex;
        justify-content: center;
    }
}
.av-login-wrap {
    width: 188px;
    height: 58px;
    padding: 8px;
    line-height: 48px;
    border-radius: 4px;
    background-color: #fff;
    position: absolute;
    right: -150px;
    bottom: 70px;
    z-index: 9999;
    box-shadow: 0px 2px 8px 0px rgba(25, 43, 89, 0.15);

    .av-login-btn {
        width: 172px;
        height: 38px;
        line-height: 38px;
        border-radius: 4px;
        text-align: left;
        padding-left: 12px;
    }
}
.av-login-pwd-wrap {
    height: 110px;
}
</style>
