import { ref, computed } from "vue";
import { defineStore } from "pinia";
import localStorage from "@/utils/localStorage";
import { routes, asyncRoutes } from "@/router";

interface UserObj {
    base_info: any;
    role_info: any;
    org_info: any;
}
interface RouterObj {
    routes: any[];
}

/**
 * 判断用户是否有权限访问单个路由
 * roles：用户角色
 * route：访问的路由
 */
const hasPermission = (roles: string[], route: any) => {
    return true;
    if (route.meta && route.meta.role) {
        return roles.some((role) => {
            if (route.meta?.role !== undefined) {
                return route.meta.role.includes(role);
            } else {
                return false;
            }
        });
    } else {
        return true;
    }
};

/**
 * 筛选可访问的动态路由
 * roles：用户角色
 * route：访问的动态列表
 */
export const filterAsyncRoutes = (routes: any[], roles: string[]) => {
    const res: any[] = [];
    routes.forEach((route) => {
        const r = { ...route };
        res.push(r);
        // if (hasPermission(roles, r)) {
        //     if (r.children) {
        //         r.children = filterAsyncRoutes(r.children, roles);
        //     }
        //     res.push(r);
        // }
    });
    return res;
};
// 保存用户email
export const defineUserStore = defineStore("user", () => {
    const userInfo = JSON.parse(localStorage.get("user") || "{}");
    const User = ref<UserObj>({
        base_info: userInfo.base_info,
        role_info: userInfo.role_info,
        org_info: userInfo.org_info,
    });
    const timezone = ref(localStorage.get("timezone") || "UTC+8");
    function setTimezone(value: string) {
        localStorage.set("timezone", value);
        timezone.value = value;
    }
    const componentKey = ref(0);
    function setComponentKey() {
        componentKey.value++;
    }
    const routerObj = ref<RouterObj>({
        routes: [],
    });
    function setRoutes(roles: string[]) {
        const accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
        routerObj.value.routes = [...routes, ...accessedRoutes];
    }
    function initUser() {
        return {
            base_info: {},
            role_info: {},
            org_info: {},
        };
    }
    function setUser(value: any) {
        localStorage.set("user", JSON.stringify(value || "{}"));
        User.value = value;
    }
    function removeUser() {
        Object.assign(User.value, initUser());
    }
    const isSuperAdmin = computed(() => {
        return true;
        return User.value?.role_info?.is_super === 1;
    });
    const isAdmin = computed(() => {
        return User.value?.base_info?.is_admin === 1;
    });
    const isBusinessUser = computed(() => {
        return true;
        return User.value?.role_info?.is_super === 3;
    });
    return {
        User,
        setUser,
        removeUser,
        routerObj,
        setRoutes,
        isSuperAdmin,
        isAdmin,
        isBusinessUser,
        timezone,
        setTimezone,
        setComponentKey,
        componentKey,
    };
});
