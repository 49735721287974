const cn = {
    btn: {
        logout: "退出登录",
        Cancel: "取消",
        Confirm: "确认",
        addOrg: "新增成员",
        operation: "操作",
        viewmember: "查看成员",
        viewpermission: "查看权限",
        create: "新建",
        querySame: "查重",
        import: "导入",
        importing: "导入中...",
        export: "导出",
        detail: "查看详情",
        edit: "编辑",
        delete: "删除",
        receive: "领取",
        receiving: "领取中...",
        deactivate: "停用",
        switchOpen: "启用",
        deactivating: "停用中...",
        transfer: "转移",
        distribute: "分配",
        distributing: "分配中...",
        transferClient: "转客户",
        associatedContacts: "关联联系人",
        disassociate: "解除关联",
        uploadAttachment: "上传附件",
        viewAllRecords: "查看所有记录",
        viewPermission: "查看权限",
        upload: "上传",
        download: "下载",
        downloading: "下载中...",
        deleting: "删除中...",
        associatedOppty: "关联商机",
        associat: "关联",
        addNewRecord: "新增记录",
        publishing: "发布中...",
        uploading: "上传中...",
        publishRecord: "发布记录",
        updating: "更新中...",
        update: "更新",
        addNewContact: "新增联系人",
        save: "保存",
        search: "查询",
        exportSelected: "导出所选",
        submit: "提交",
        importOppty: "商机导入",
        importContact: "联系人导入",
        importLead: "线索导入",
        putInLeadPool: "放入线索池",
    },
    menu: {
        title: "go-corn",
        dashboard: "仪表盘",
    },
    users: {
        avatar: "头像",
        name: "姓名",
        userName: "用户名",
        baiduEmail: "百度邮箱",
        belongDepartment: "所属部门",
        departmentManagers: "部门主管",
        role: "角色",
    },
    selectOptions: {
        myselfSubordinates: "本人及下属",
        myselft: "本人",
        subordinates: "下属",
        followUpStatus: "跟进状态",
        notAllocated: "未分配",
        pending: "待处理",
        invalidLead: "无效线索",
        noReplay: "未回复",
        followingUp: "跟进中",
        noIntention: "无意向",
        converted: "已转化",
        terminated: "已停用",
        dealStatus: "成交状态",
        closedDeal: "已成交",
        notClosed: "未成交",
        marketEventName: "市场活动名称",
        marketEvent: "市场活动",
        selfExpansion: "自拓展",
        supervisorAllocation: "主管分配",
        superKeyAccount: "超级KA客户",
        keyAccount: "KA客户",
        mediumAccount: "中型客户",
        smallAccount: "小型客户",
        serviceProvider: "服务商",
        ceo: "CEO",
        cmo: "CMO",
        otherManagement: "公司其他管理层",
        businessDirector: "商务负责人",
        businessManager: "商务",
        accountDirector: "运营负责人",
        accountManager: "运营",
        brandDirector: "品牌负责人",
        brandManager: "品牌对接人",
        others: "其他",
        clientVerification: "验证客户",
        needsAnalysis: "需求分析",
        proposalQuotation: "方案报价",
        businessNegotiation: "商务谈判",
        orderWon: "赢单",
        orderLost: "输单",
        salesOpportunityStage: "商机阶段",
        rebatePolicyNotMeetClientExpectations: "返点政策不符合客户预期",
        paymentPolicyNotMeetClientExpectations: "账期政策不符合客户预期",
        clientNotProvideMaterials: "客户无法提供佐证材料",
        productChanges: "产品有变动",
        paymentIssues: "支付方式原因",
        otherReasons: "其他原因（自定义填写）",
        opptyStageText1:
            "了解客户公司基本情况（如公司背景和人员规模、主营业务、目标国家和地区等）",
        opptyStageText2: "了解客户在其他渠道的广告消耗规模",
        opptyStageText3:
            "了解客户公司的业务需求（如推广产品、投放目标、广告预算、投放痛点等）",
        opptyStageText4: "了解客户是否需要代投服务",
        opptyStageText5: "向客户推荐投放渠道，并介绍相关媒体和优秀案例",
        opptyStageText6: "向客户提供商务政策（如账期、返点、服务费等）",
        opptyStageText7: "与客户确认投放渠道和商务政策",
        opptyStageText8: "向客户提供媒体投放方案",
        opptyStageText9:
            "如客户申请特殊账期和返点政策，搜集佐证材料进行内部申请",
        man: "男",
        women: "女",
    },

    backEnd: {
        superAdmin: "超级管理员",
        superAdminText1: "预置角色,不可删除,支持当前所有组织数据的所有读写操作",
        businessManager: "商务经理",
        businessManagerText1:
            "预置角色,不可删除,访问个人及本下级所有数据的所有读写操作",
        businessSpecialist: "商务专员",
        businessSpecialistText1:
            "预置角色,不可删除,支持访问个人所有数据的部分读写操作",
    },
    common: {
        distributeAccount: "客户分配",
        distributeLead: "线索分配",
        clientFollowupTip: "客户跟进提醒",
        dataSum: "数据汇总",
        compareLastMonth: "较上月",
        new: "新增",
        salesBrief: "销售简报",
        module: "模块",
        moduleName: "模块名称",
        phoneEmailWechat: "手机号/邮箱/微信号",
        clientDuplication: "客户查重",
        addNewOppty: "新增商机",
        conversionRate: "转化率",
        selected: "已选择",
        records: "条",
        switchOpen: "启用",
        switchClose: "禁用",
        uploador: "上传人",
        opptyNumbers: "商机数量",
        isPrimaryContact: "是否首要联系人",
        yes: "是",
        no: "否",
        time: "时间",
        followupRecords: "跟进记录",
        details: "详细资料",
        attachment: "附件",
        contactExperience: "联系人经历",
        systemMsg: "系统信息",
        baseInfo: "基本信息",
        promotionalInfo: "推广信息",
        attachmentName: "附件名称",
        attachmentSize: "附件大小",
        uploadTime: "上传时间",
        noPermission: "无权限",
        leadName: "线索名称",
        leadSource: "线索来源",
        leadLevel: "线索等级",
        relatedMarketEvents: "关联市场活动",
        industry: "客户行业",
        addressAll: "地址-省/市/区",
        city: "所在城市",
        address: "详细地址",
        contactPerson: "联系人",
        gender: "性别",
        position: "职务",
        cellPhone: "手机",
        telePhone: "电话",
        wechat: "微信",
        email: "邮箱",
        productName: "产品名称",
        companySite: "公司官网",
        subcategories: "细分品类",
        targetLocation: "推广区域",
        aveDailySpend: "竞媒日均消耗",
        aveDailyBudget: "日均推广预算",
        notes: "备注",
        customTag: "自定义标签",
        followupRecord: "跟进记录",
        followupStatus: "跟进状态",
        personCharge: "负责人",
        lastContactTime: "最近沟通时间",
        lastFollowuoRecord: "最新跟进记录",
        duration: "持续时间",
        creator: "创建人",
        creationTime: "创建时间",
        clientName: "客户名称",
        clientSource: "客户来源",
        clientLevel: "客户等级",
        opptyName: "商机名称",
        opptyAmount: "商机金额",
        opptyAmountUnit: "商机金额（元）",
        estimatedTime: "预计成交时间",
        businessLine: "业务线",
        cooperationChannels: "合作渠道",
        cooperationMethod: "合作方式",
        rebate: "返点",
        legalEntity: "签约主体",
        productLink: "产品链接",
    },
    date: {
        today: "今天",
        yesterday: "昨天",
        tommorow: "明天",
        thisWeek: "本周",
        lastWeek: "上周",
        nextWeek: "下周",
        thisMonth: "本月",
        lastMonth: "上月",
        nextMonth: "下月",
        thisQuarter: "本季度",
        lastQuarter: "上季度",
        nextQuarter: "下季度",
        thisYear: "今年",
        lastYear: "去年",
        nextYear: "明年",
    },
    msg: {
        deleteDone: "删除成功",
        confirmDone: "保存成功",
        updateDone: "更新成功",
        stopDone: "停用成功",
        receiveDone: "领取成功",
        receiveError: "领取失败",
        confirmError: "保存失败，请稍后再试",
        uploadError: "附件上传失败，请重新上传",
        workDone: "操作成功",
        workError: "操作失败",
        waitExport: "正在导出中，耐心等待...",
        importDone: "导入成功",
        exportDone: "导出成功",
        exportError: "导出失败",
    },
};

export default cn;
