import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const defineTaskStore = defineStore("task", () => {
    const Task_id = ref("");
    const Log_task_id = ref<number | string>();
    const setTaskId = (id: string) => {
        Task_id.value = id;
    };
    const setLogId = (id: number) => {
        Log_task_id.value = id;
    };
    const removeTaskId = () => {
        Task_id.value = "";
    };
    const removeLogId = () => {
        Log_task_id.value = "";
    };
    return {
        Task_id,
        setTaskId,
        removeTaskId,
        Log_task_id,
        setLogId,
        removeLogId,
    };
});
