import axios, { type AxiosRequestConfig, type AxiosResponse } from "axios";
import qs from "qs";
import { Modal, message } from "ant-design-vue";
import router from "@/router";
import i18n from "../i18n/index";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { createVNode } from "vue";

declare module "axios" {
    interface AxiosResponse<T = any> {
        code: number;
        errno: number;
        errmsg: string;
        message: string;
        data: T;
    }
    export function create(config?: AxiosRequestConfig): AxiosInstance;
}

const reqQueue: any = {};
const baseUrl = import.meta.env.BASE_URL;

const originalUrl = window.location.href;
const pageOrigin = window.location.origin;
let messageBoxFlag = false;

/**
 * 文件下载
 * @param {ArrayBuffer} response
 */

function downloadFile(data: ArrayBuffer, fileName: string, type?: string) {
    const url = window.URL.createObjectURL(
        new Blob([data], { type: type || "text/plain,charset=UTF-8" })
    );
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

/**
 * 请求节流
 */
function handleThrottle(url: string) {
    reqQueue[url] && reqQueue[url].abort();
    const controller = new AbortController();
    reqQueue[url] = controller;
    return controller.signal;
}
const service = axios.create({
    // baseURL: 'http://localhost:3100',
    baseURL: import.meta.env.BASE_URL,
    withCredentials: true,
    headers: {
        OriginHost: pageOrigin,
        originalUrl,
    },
    timeout: 50000,
});

service.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        if (messageBoxFlag) {
            return Promise.reject();
        }
        let url = config.url || "";
        // get参数编码
        if (
            (config.method === "get" || config.method === "delete") &&
            config.params
        ) {
            url += "?";
            const keys = Object.keys(config.params);
            for (const key of keys) {
                if (config.params[key] !== undefined) {
                    url += `${key}=${encodeURIComponent(config.params[key])}&`;
                }
            }
            url = url?.substring(0, url.length - 1);
            config.params = {};
        }
        config.url = url;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

service.interceptors.response.use(
    (response: AxiosResponse) => {
        // throttle reset
        const url: string = response.config.url?.split("?")[0] || "";
        reqQueue[url] = null;
        const { t } = i18n.global;
        if (response.status == 200) {
            if (
                response.headers["content-type"] === "application/octect-stream"
            ) {
                //下载
                const header = response.headers;
                const fileName =
                    (header["content-disposition"] &&
                        decodeURI(
                            header["content-disposition"].split("filename=")[1]
                        )) ||
                    "filename";
                downloadFile(
                    response.data,
                    fileName,
                    response.headers["content-type"]
                );
            }
            else {
                const { data } = response;
                // 成功
                if (data.code === 0 || data.errno === 0) {
                    return Promise.resolve(data);
                } else if (data.errno === 302 && data.errmsg) {
                    const mode = import.meta.env.VITE_APP_MODE
                    if(mode === "gcs"){

                        router.replace("/login");
                        return;
                    }
                    if (!messageBoxFlag) {
                        messageBoxFlag = true;
                        window.localStorage.removeItem("user");
                        Modal.confirm({
                            title: "请重新登录",
                            icon: createVNode(ExclamationCircleOutlined),
                            okText: "登录",
                            cancelText: "取消",
                            okType: "primary",
                            content: "登录已过期，确定要重新登录吗?",
                            onOk() {
                                messageBoxFlag = false;
                                window.location.href = data.errmsg;
                            },
                            onCancel() {
                                messageBoxFlag = false;
                                console.log("error");
                            },
                            class: "test",
                        });
                    }
                } else if (data.code === 302 && data.message) {
                    router.replace("/login");
                    return;
                } else if (data.errno === 401) {

                    message.error(data.errmsg || "无权限！", 3).then(() => {
                    });
                } else {
                    const errMsg = data.message || data.errmsg;

                    if (errMsg) {
                        message.error(t(errMsg));
                    }
                    // 异常情况
                    response.data.code = data.code || data.errno;

                    return Promise.reject(t(errMsg));
                }
            }
            return Promise.resolve(response.data);
        }
        message.error(
            response.data.errmsg ? t(response.data.errmsg) : "服务错误"
        );

        return Promise.resolve(response.data);
    },
    (error) => {
        if (axios.isCancel(error)) {
            return new Promise(() => ({}));
        }
        if (error?.response?.status == 401) {
            message.warning("身份已过期，请重新登录");
            router.replace("/login");
            // location.reload()
        } else if (error?.response?.status == 403) {
            message.warning("无权限，请联系管理员！");
        }
        return Promise.reject(error);
    }
);

export default service;

interface optionParams {
    throttle?: boolean; // 是否开启节流
}

const returnUrl = (url: string) => {
    return baseUrl;
};

export function httpGet<T = {}>(
    url: string,
    params?: T,
    option: optionParams = { throttle: true }
) {
    const baseURL = returnUrl(url);
    const config: AxiosRequestConfig = {
        baseURL,
        url,
        method: "get",
        params: params,
        headers: {
            timeZone: window.localStorage.getItem("timezone") || "UTC+8",
            lang: window.localStorage.getItem("lang") || "cn",
            Accept: "application/json",
            "Content-Type": "application/json; charset=UTF-8",
        },
    };
    // throttle
    if (option.throttle) {
        config.signal = handleThrottle(url);
    }
    return service(config);
}

export function httpPost<T = {}>(url: string, data?: T) {
    const baseURL = returnUrl(url);
    return service({
        baseURL,
        url,
        method: "post",
        data: qs.stringify(data),
        headers: {
            timeZone: window.localStorage.getItem("timezone") || "UTC+8",
            lang: window.localStorage.getItem("lang") || "cn",
            accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
            Host: "go-cron-fe.test.gbu-eks.appspace.baidu.com",

            // 'Content-Type': 'application/json; charset=UTF-8'
        },
    });
}

export function httpPostJson<T = {}>(url: string, data?: T) {
    const baseURL = returnUrl(url);
    return service({
        baseURL,
        url,
        method: "post",
        data: data,
        headers: {
            timeZone: window.localStorage.getItem("timezone") || "UTC+8",
            lang: window.localStorage.getItem("lang") || "cn",
            accept: "application/json",
            "Content-Type": "application/json; charset=UTF-8",
        },
    });
}
export function httpDownload<T = {}>(url: string, params?: T) {
    const baseURL = returnUrl(url);
    return service({
        baseURL,
        url,
        method: "get",
        params,
        responseType: "arraybuffer",
        headers: {
            timeZone: window.localStorage.getItem("timezone") || "UTC+8",
            lang: window.localStorage.getItem("lang") || "cn",
            Accept: "text/csv",
            "Content-Type": "text/csv;charset=UTF-8",
        },
    });
}

export function httpPostDownload<T = {}>(url: string, data?: T) {
    const baseURL = returnUrl(url);
    return service({
        baseURL,
        url,
        method: "post",
        data: qs.stringify(data),
        responseType: "arraybuffer",
        headers: {
            timeZone: window.localStorage.getItem("timezone") || "UTC+8",
            lang: window.localStorage.getItem("lang") || "cn",
            // Accept: 'text/csv',
            // 'Content-Type': 'text/csv;charset=UTF-8'
        },
    });
}

export function httpUpload<T = {}>(url: string, data?: T, config?: T) {
    const baseURL = returnUrl(url);
    return service({
        baseURL,
        url,
        method: "post",
        data,
        headers: {
            timeZone: window.localStorage.getItem("timezone") || "UTC+8",
            lang: window.localStorage.getItem("lang") || "cn",
            Accept: "application/form-data",
            "Content-Type": "application/form-data; charset=UTF-8",
        },
        ...config,
    });
}
