declare let window: any;
import { computed, nextTick } from "vue";
import type { RouteLocationNormalized } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";
import { defineUserStore } from "@/stores/user";
// import {routesModule} from '@/stores/permission';
import Login from "@/views/login/index.vue";
import Password from "@/views/login/password.vue";
export interface RouteItem {
    path: string;
    name?: string;
    redirect?: string;
    icon?: string;
    hidden?: boolean;
    children?: ChildrenItem[];
    component?: Object;
    meta?: Object;
}
export interface ChildrenItem {
    path: string;
    name: string;
    icon: string;
    component?: object;
    hidden?: boolean;
    redirect?: string;
    children?: ChildrenItem[];
    meta?: Object;
}
// 根级目录添加redirect可保证面包屑根目录点击可正常跳转
// 子路由path不需加/，el-menu-item中已做拼接
export const routes: RouteItem[] = [
    {
        path: "/",
        redirect: "/task",
        hidden: true,
    },
    {
        path: "/login",
        component: Login,
        name: 'Login',
        meta: {
            hideNav: true,
        },
        hidden: true,
    },
    {
        path: "/update",
        component: Password,
        name: "UpdateUser",

        hidden: true,
    },
    {
        path: "/task",
        name: "TaskList",
        icon: "menu-dashboard-icon",
        component: () => import("@/views/task/index.vue"),
    },
    {
        path: "/user_manager",
        name: "userManager",
        component: () => import("@/views/userManager/index.vue"),
    },
    {
        path: "/user_group",
        name: "UserGroup",
        component: () => import("@/views/userGroup/index.vue"),
    },
    {
        path: "/task_log",
        name: "TaskLog",
        icon: "menu-dashboard-icon",
        component: () => import("@/views/task/log.vue"),
    },
    {
        path: "/host",
        name: "Node",
        component: () => import("@/views/node/index.vue"),
    },
    {
        path: "/sysmanage",
        name: "Sys",
        component: () => import("@/views/sysmanage/index.vue"),
    },
    {
        path: "/sysmanage-log",
        name: "SysLog",
        component: () => import("@/views/sysmanage/log.vue"),
    },
    {
        path: "/404",
        name: "404",
        component: () => import("@/views/404/index.vue"),
        hidden: true,
    },
    {
        path: "/:pathMatch(.*)",
        redirect: "/404",
        hidden: true,
    },
];
export const asyncRoutes: RouteItem[] = [
    // 系统设置
];
const router = createRouter({
    //https://router.vuejs.org/zh/api/index.html#createwebhashhistory
    history: createWebHistory("/"),
    routes: routes as any,
});
router.beforeEach(
    async (
        to: RouteLocationNormalized,
        from: RouteLocationNormalized,
        next: any
    ) => {
        next();
        return;
        const userStore = defineUserStore();
        // const allRoutes = routesModule();
        const roleId = computed(() => {
            return userStore.User?.role_info?.role_id || "";
        });
        // const routerList = allRoutes.routerObj?.routes || [];
        if (to.path === "/404") {
            next();
            return;
        }
        if (!roleId.value) {
            next({ path: "/404", replace: true });
            return;
        }
        const routeRoles = (to?.meta?.role || []) as string[];
        if (
            !routeRoles.length ||
            routeRoles.includes(roleId.value) ||
            roleId.value === 1
        ) {
            next();
            return;
        }
        next({ path: "/lead", replace: true });
    }
);

export default router;
