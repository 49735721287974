import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";
import i18n from "@/i18n";
import Antd from "ant-design-vue";
import VueLazyload from "vue-lazyload";

import "./assets/main.css";
// 注册所有图标
import "virtual:svg-icons-register"; // 引入注册脚本

const app = createApp(App);
app.use(createPinia());

app.use(router);
app.use(i18n);
app.use(VueLazyload);
app.use(Antd);

app.mount("#app");
