import { httpPost, httpGet } from "@/utils/request";

export const getUserInfo = () => httpGet("/api/user/info", {});
export const getUserById = (id: string) => httpGet(`/api/user/${id}`);
export const Logout = () => httpGet("/api/xuuap/logout", {});

type User = {
    username: string;
    password: string;
}
export const Loginin = (params:User) => httpPost("/api/user/login", params);

// 获取通知用户

export const mail = () => httpGet("/api/system/mail", {});
