const en = {
    btn: {
        logout: "Sign Out",
        Cancel: "Cancel",
        Confirm: "Confirm",
        addOrg: "Add New Member",
        operation: "Operate",
        viewmember: "View Members",
        viewpermission: "View Permissions",
        create: "Add New",
        querySame: "Check for Plagiarism",
        import: "Import",
        export: "Export",
        detail: "Check for Details",
        edit: "Edit",
        delete: "Delete",
        receive: "Receive",
        deactivate: "Deactivate",
        transfer: "Transfer",
        distribute: "Disribute",
        transferClient: "Tranfer Client",
        associatedContacts: "Associated Contacts",
        disassociate: "Disassociate",
        uploadAttachment: "Upload Attachment",
        viewAllRecords: "View All Records",
        viewPermission: "View Permissions",
        downLoad: "Download",
        deleting: "Deleting",
    },
    menu: {
        title: "go-corn",
        dashboard: "DashBoard",
    },
    users: {
        avatar: "Profile Picture",
        name: "Name",
        userName: "User Name ",
        baiduEmail: "Baidu Email ",
        belongDepartment: "Department",
        departmentManagers: "Department Managers",
        role: "Role",
    },
    backEnd: {
        superAdmin: "Super Admin",
        superAdminText1:
            "Preset Roles, Cannot Be Deleted, Support All Read And Write Access of All Current Organization Data",
        businessManager: "Business Development Manager",
        businessManagerText1:
            "Preset Roles, Cannot Be Deleted, Access All Read And Write Access of Personal And Subordinate Data",
        businessSpecialist: "Business Development Specialist",
        businessSpecialistText1:
            "Preset Roles, Cannot Be Deleted, Support Partial Read And Write Access to Access All Personal Data",
    },

    date: {
        today: "today",
        yesterday: "yesterday",
        tommorow: "Tommorow",
        thisWeek: "This Week",
        lastWeek: "Last Week",
        nextWeek: "Next Week",
        thisMonth: "This Month",
        lastMonth: "Last Month",
        nextMonth: "Next Month",
        thisQuarter: "This Quarter",
        lastQuarter: "Last Quarter",
        nextQuarter: "Next Quarter",
        thisYear: "This Year",
        lastYear: "Last Year",
        nextYear: "Nex Year",
    },
};

export default en;
