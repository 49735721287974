<template>
    <a-config-provider :autoInsertSpaceInButton="false">
        <a-layout
            v-if="!isHiddenNav"
            class="layout-app"
            :locale="zhCN"
            >
            <!-- 侧边 -->
            <a-layout-sider
                width="200"
                v-model:collapsed="collapsed"
                :trigger="null"
                collapsible

            >
                <layout-menu :collapsed="collapsed" />
            </a-layout-sider>

            <a-layout>
                <!-- 内容 -->
                <a-layout-content class="container">
                    <layout-tabs />
                </a-layout-content>
            </a-layout>
        </a-layout>
        <LoginIN v-else>

        </LoginIN>

    </a-config-provider>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, computed} from 'vue';
import { defineUserStore } from '@/stores/user';
import { getUserInfo, getUserById } from '@/apis/user';
import LayoutMenu from '@/layout/menu/menu.vue';
import LayoutTabs from '@/layout/tabs/tabs.vue';
import LoginIN from '@/views/login/index.vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import { useRoute } from 'vue-router';
import Cookies from 'js-cookie';

const userStore = defineUserStore();

// 用于面包屑
const pageLoading = ref(false);
// resizeChart
const route = useRoute();

// 是否隐藏左侧边栏跟头部
const isHiddenNav = computed(() => {
    return route.meta.hideNav === undefined ? false : route.meta.hideNav;
});
const uId = computed(() => {
    return Cookies.get('goUId') || '';
});
const getUserInfos = async () => {
    pageLoading.value = true;
    const data = await getUserInfo();
    if (data.code === 0) {
        const res = data.data
            ? {
                  base_info: data.data,
                  role_info: {
                      role_id: data.data?.is_admin || 0,
                  },
              }
            : {};

        userStore.setUser(res);
        const roleId = res?.role_info?.role_id || '';
        roleId && userStore.setRoutes([roleId]);

    }
    pageLoading.value = false;
};
const getUserByIds = async () => {
    console.log('goUId.value', uId.value);
    pageLoading.value = true;

    const data = await getUserById(uId.value);
    if (data.code === 0) {
        const res = data.data
            ? {
                  base_info: data.data,
                  role_info: {
                      role_id: data.data?.is_admin || 0
                  }
              }
            : {};

        userStore.setUser(res);
        const roleId = res?.role_info?.role_id || '';
        roleId && userStore.setRoutes([roleId]);

    }
    pageLoading.value = false;
};
const collapsed = ref<boolean>(false);


// 在应用入口文件中调用 useUnderList，确保在应用挂载之前进行初始化
onBeforeMount(() => {
    if (import.meta.env.VITE_APP_MODE !== 'gcs') {
        getUserInfos();
    } else {
        setTimeout(() => {
            if (route.name !== 'Login') {
                getUserByIds();
            }
        }, 0);
    }
});

</script>
<style lang="scss" scoped>
.layout-app {
    overflow: hidden;
    height: 100vh;
    & .header {
        background-color: #fff;
        padding: 0;
    }
    & .container {
        padding: 17px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    & .container::-webkit-scrollbar {
        width: 6px;
    }
    & .container::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 5px #d8d8d8;
        background: #c1c1c1;
    }
    & .container::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #d8d8d8;
        background: #ededed;
    }
}
</style>
