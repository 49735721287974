import { createI18n } from 'vue-i18n'
import messages from './langs';

const getDefaultLang = () => {
    const lang = localStorage.getItem('lang') || navigator.language || 'zh-CN'
    const isCn = lang.indexOf('zh') > -1 || lang.indexOf('cn') > -1
    return isCn ? 'cn' : 'en'
}

const i18n = createI18n({
    locale: getDefaultLang(),
    legacy: false, // 如果要支持compositionAPI，此项必须设置为false;
    globalInjection: true, // 全局注册$t方法
    messages,
})

export default i18n